import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CATEGORY } from 'Component/Header/Header.config';
import {
    LOADING_TIME
} from 'Route/CategoryPage/CategoryPage.config';
import {
    CategoryPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'Route/CategoryPage/CategoryPage.container.js';
import CategoryReducer from 'Store/Category/Category.reducer';
import { updateMeta } from 'Store/Meta/Meta.action';
import { scrollToTop } from 'Util/Browser';
import { withReducers } from 'Util/DynamicReducer';
import { debounce } from 'Util/Request';

import InsignCategoryPage from './InsignCategoryPage.component';

/** @namespace Insign/ScandipwaExtCatalog/Route/InsignCategoryPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    category: {},
    attributeMetadata: state.ProductListInfoReducer.attributeMetadata
});

/** @namespace Insign/ScandipwaExtCatalog/Route/InsignCategoryPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateMeta: (meta) => dispatch(updateMeta(meta))
});

/** @namespace Insign/ScandipwaExtCatalog/Route/InsignCategoryPage/Container */
export class InsignCategoryPageContainer extends CategoryPageContainer {
    static propTypes = {
        ...CategoryPageContainer.propTypes,
        canonical_url: PropTypes.string
    };

    static defaultProps = {
        ...CategoryPageContainer.defaultProps,
        canonical_url: ''
    };

    static getDerivedStateFromProps(props, state) {
        const {
            defaultPlpType,
            plpTypes
        } = state;

        const {
            plpType
        } = props;

        const update = {};

        /**
         * Determine default plpType and the other ones
         */
        if (!defaultPlpType || !plpTypes) {
            if (plpType.match('-')) {
                const plpTypes = plpType.split('-');

                Object.assign(update, { defaultPlpType: plpTypes[0], plpTypes });
            } else {
                Object.assign(update, { defaultPlpType: plpType, plpTypes: [plpType] });
            }
        }

        /**
         * If the category we expect to load is loaded - reset it
         */

        if (!Object.keys(update).length) {
            return null;
        }

        return update;
    }

    isCurrentCategoryLoaded() {
        return true;
    }

    updateHistory() {
        return null;
    }

    updateBreadcrumbs(isUnmatchedCategory = false) {
        const { updateBreadcrumbs, category } = this.props;
        const extCatalogObj = this.urlExtcatalogStringToObject();

        updateBreadcrumbs({ name: decodeURIComponent(extCatalogObj.title) });

        this.setState({ breadcrumbsWereUpdated: true });
    }

    updateHeaderState(isUnmatchedCategory = false) {
        const {
            changeHeaderState,
            history
        } = this.props;

        const onBackClick = () => history.goBack();

        const { title } = this.urlExtcatalogStringToObject();

        /**
         * Ensure the name is not set if the category IDs do not
         * match. Otherwise, the previous value is displayed.
         */
        changeHeaderState({
            name: CATEGORY,
            title: decodeURI(title),
            onBackClick
        });
    }

    updateMeta() {
        const {
            updateMeta
        } = this.props;

        const {
            title
        } = this.urlExtcatalogStringToObject();

        updateMeta({ title: decodeURI(title) });
    }

    getIsMatchingInfoFilter() {
        // Requested category is equal to current category
        const {
            attributeMetadata
        } = this.props;

        return attributeMetadata.length;
    }

    componentDidMount() {
        scrollToTop();
        this.updateNavigationState();

        this.updateMeta();
        this.updateBreadcrumbs();
        this.updateHeaderState();
    }

    componentDidUpdate(prevProps) {
        const {
            isOffline,
            canonical_url
        } = this.props;

        // TODO: category scrolls up when coming from PDP

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        if (prevProps.canonical_url !== canonical_url) {
            scrollToTop();
            this.updateMeta();
            this.updateBreadcrumbs();
            this.updateHeaderState();
        }
    }

    urlExtcatalogStringToObject() {
        const { canonical_url } = this.props;

        const extCatalogParams = canonical_url.split('?')[1];

        if (extCatalogParams) {
            return extCatalogParams.split('&').reduce((acc, part) => {
                const [key, value] = part.split('=');

                return { ...acc, [key]: value };
            }, {});
        }

        return {};
    }

    isCigarFinderAvailable() {
        return true;
    }

    isNoFilters(extUrlObject) {
        if (extUrlObject.by_attribute_set) {
            switch (extUrlObject.by_attribute_set) {
            case 'Zigarillos':
            case 'Gutschein':
            case 'Sampler':
                return true;
            default:
                return false;
            }
        }

        if (extUrlObject.by_productgroup) {
            switch (extUrlObject.by_productgroup) {
            case 'Bestseller':
            case 'Bundle':
            case 'Limited':
            case 'Ediciones%20Limitadas':
            case 'Ediciones%20Regionales':
            case 'Ediciones%20Casa':
                return true;
            default:
                return false;
            }
        }

        if (extUrlObject.by_special_price) {
            return true;
        }

        if (extUrlObject.by_new_arrivals) {
            return true;
        }

        return false;
    }

    containerProps() {
        const extUrlObject = this.urlExtcatalogStringToObject();

        return {
            ...super.containerProps(),
            urlExtcatalogObject: extUrlObject,
            isBrandPage: extUrlObject.by_brand,
            isCountryPage: extUrlObject.by_country,
            noFilters: this.isNoFilters(extUrlObject),
            isCigarFinderAvailable: extUrlObject.has_cigarfinder === '1'
        };
    }

    render() {
        const { pageSize } = this.config;
        const {
            defaultPlpType,
            selectedLayoutType,
            activeLayoutType
        } = this.state;

        return (
            <InsignCategoryPage
              pageSize={ pageSize }
              defaultPlpType={ defaultPlpType }
              selectedLayoutType={ selectedLayoutType }
              activeLayoutType={ activeLayoutType }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({
    CategoryReducer
})(connect(mapStateToProps, mapDispatchToProps)(InsignCategoryPageContainer));
